import 'idempotent-babel-polyfill';
require('es6-promise').polyfill();
window.axios = require('axios');

import Helpers from '../Helpers';

(function () {
    class RecommendRedirectToApp {
        constructor() {
            this.helpers = new Helpers();
            this.sMallId = EC_FRONT_JS_CONFIG_MANAGE.sMallId;
            this.sShopNo = EC_SDE_SHOP_NUM;
            this.sNation = EC_GLOBAL_INFO.getCountryCode().toLocaleLowerCase();
            this.aAddServiceInfo = [];
            this.sModule = 'recommend-redirect-to-app';
            this.sCookieName = 'DO_NOT_DISPLAY_RECOMMEND_REDIRECT_TO_APP_POPUP';
            this.sAppUrl = this.helpers.getAppUrl(this.sModule);
            this.detected_client = null;
            this.oSettings = null;
            this.aUrlPaths = [];
            this.aAllowedPaths = ['/product/detail', '/product/list', 'board/gallery/read', '/product/search'];
            if (this.helpers.isPreviewMode() === true) {
                $('body').css('pointer-events', 'none');
            }
        }

        /**
         * Main method.
         * @return {Promise<void>}
         */
        async init() {
            this.detected_client = this.getDetectedOsClient();
            if (this.helpers.isUnsupportedAgent() === true || this.isPopupCloseOrContinueButtonClicked() === true || this.detected_client === null) {
                return;
            }
            this.addCssScript();
            await this.getUrlPaths();
            this.renderPopup();
        }

        /**
         *
         * @returns {Promise<[]>}
         */
        async getUrlPaths() {
            this.oSettings = await this.getSettings();
            this.oSettings.settings.themes.forEach((mItem) => {
                this.getDisplayUrl(mItem.url);
            });
            return this.aUrlPaths;
        }
        /**
         *
         * @param oUrl
         * @returns {Promise<void>}
         */
        async getDisplayUrl(oUrl) {
            oUrl.forEach((mItem) => {
                this.aUrlPaths.push(mItem.display_url);
            });
        }

        /**
         * Delay popup for a second.
         * @return {Promise<void>}
         */
        async delayPopupIfInstallPopupNotYetPresent() {
            let oRecommendSettings = this.oSettings.recommend_install;
            const DELAY_IN_SECONDS = 1,
                MAX_VISIT_COUNT = 3;
            let sRecommendDontDisplayCookieName = 'DO_NOT_DISPLAY_RECOMMEND_POPUP';
            let sRecommendCookieCount = 'RECOMMEND_POPUP_COUNT';
            let iPageVisitCount = oRecommendSettings.popup_timing === 'count' ? parseInt(this.helpers.getCookie(sRecommendCookieCount), 10) || 0 : MAX_VISIT_COUNT;
            if (oRecommendSettings.recommend_install_enabled === true && iPageVisitCount === MAX_VISIT_COUNT && this.helpers.getCookie(sRecommendDontDisplayCookieName) !== 'true') {
                await this.pauseBySeconds(DELAY_IN_SECONDS);
            }
            return false;
        }

        /**
         * Process popup
         * @return {Promise<void>}
         */
        async renderPopup() {
            await this.delayPopupIfInstallPopupNotYetPresent();
            if (this.oSettings === false) {
                return;
            }

            this.aAddServiceInfo = this.oSettings.plusapp_service_info;
            if (
                this.isInstallPopupPresent() === true ||
                this.oSettings.settings.show_popup === false ||
                this.oSettings.settings.plusapp_manager_enabled === false ||
                this.oSettings.settings.show_popup === false ||
                this.aAddServiceInfo.service_status === false ||
                this.detected_client.checkServiceOsAvailability() === false
            ) {
                return;
            }
            if (this.isDisplayTypeDefault() === true && this.isHomePage() === true) {
                return this.displayPopUp();
            } else if (this.isDisplayTypeCustom() === true) {
                return await this.checkThemesSetting();
            } else {
                return;
            }
        }

        /**
         * the actual functio to show popup
         */
        displayPopUp() {
            // iPopupTimeout is set to 0 if we want to display popup immediately
            // Set PopupTimeout to 1000 if we want to try to autoredirect
            let iPopupTimeout = 0;

            if (this.helpers.isPreviewMode() === false && this.isInstallPopupPresent() === false && this.oSettings.settings.is_auto_redirect === true) {
                this.detected_client.openAppOnly(this.getPageFilePathInfo(this.oSettings.settings.themes));
                iPopupTimeout = 1000;
            }
            this.helpers.showRedirectPopup(this.oSettings.settings);
            this.addEventListener(this.oSettings.settings.themes);
            setTimeout(() => {
                $('#mCafe24DirectToApp').children().css('display', 'block');
                $('#mCafe24DirectToApp:is(.topTheme1, .topTheme2, .newImageTopTheme1, .newImageTopTheme2, .newImageTopTheme4) .layerBanner').css('display', 'flex');
            }, iPopupTimeout);
        }

        /**
         *
         * @returns {boolean}
         */
        isDisplayTypeDefault() {
            return this.oSettings.settings.display_type === 'default';
        }

        /**
         *
         * @returns {boolean}
         */
        isDisplayTypeCustom() {
            return this.oSettings.settings.display_type === 'custom';
        }

        /**
         *
         * @returns {boolean}
         */
        isHomePage() {
            let sCurrentUrl = decodeURI(`${location.pathname}${location.search}`);
            return sCurrentUrl === '/' || sCurrentUrl === '/m';
        }

        /**
         *
         * @returns {Promise<void>}
         */
        async checkThemesSetting() {
            return this.oSettings.settings.themes.forEach((oThemes) => {
                if (oThemes.is_specific_popup === true) {
                    //check specific file paths eg /product/sample-1/10/2?id=1
                    return this.checkUrl();
                } else {
                    //check themes page file path eg /product/detail.html
                    return this.checkPage(oThemes.url, oThemes.themes_path);
                }
            });
        }
        /**
         *
         * @param oUrl
         */
        checkPage(oUrl, sThemesPath = null) {
            oUrl.forEach((oData) => {
                // show popup on custom home page
                if (this.isCustomHomePage(oData.display_url) === true) {
                    this.displayPopUp();
                    return;
                }
                // show popup on custom file
                if (this.isCustomDisabledSpecificPage(oData.display_url, sThemesPath) === true) {
                    this.displayPopUp();
                    return;
                }
                return;
            });
        }

        /**
         *
         * @param sUrl
         * @returns {boolean|boolean}
         */
        isCustomHomePage(sUrl) {
            return this.isDisplayUrlNull(sUrl) === true && this.isHomePage() === true;
        }

        isCustomDisabledSpecificPage(sUrl, sThemesPath = null) {
            let sCurrentUrl = decodeURI(`${location.pathname}${location.search}`);
            return sCurrentUrl === sThemesPath;
        }

        /**
         *
         * @param sUrl
         * @returns {boolean}
         */
        isDisplayUrlNull(sUrl) {
            return sUrl === null || sUrl === 'null' || sUrl === '';
        }

        /**
         *
         * @returns {Promise<void>}
         */
        async checkUrl() {
            let sCurrentUrl = decodeURI(`${location.pathname}${location.search}`);
            this.aUrlPaths.forEach((sPath) => {
                sPath = this.removeTrailingSlash(sPath);
                if (sCurrentUrl.includes(sPath) === true) {
                    this.displayPopUp();
                    return;
                }
            });
        }

        /**
         *
         * @param sPath
         * @returns {string|void|*}
         */
        removeTrailingSlash(sPath) {
            if (sPath === null || sPath === '') {
                return sPath;
            }
            return sPath.replace(/\/+$/, '');
        }

        /**
         * Add event listener on popup
         */
        addEventListener(aThemes) {
            if (this.helpers.isPreviewMode() === true) {
                return false;
            }
            const oPopupDom = this.getPopupDom();
            const oFilePathInfo = this.getPageFilePathInfo(aThemes);
            oPopupDom.close_dom.forEach((oCloseDom) => {
                oCloseDom.addEventListener('click', () => {
                    oPopupDom.main_dom.style.display = 'none';
                    this.dontShowPopupForADay();
                });
            });

            oPopupDom.direct_to_app_dom.addEventListener('click', () => {
                oPopupDom.main_dom.style.display = 'none';
                this.detected_client.openAppStore(oFilePathInfo);
            });
        }

        /**
         * Get Html Page's File Path Info
         * @param aThemes
         * @param oMetaTagDom
         * @return {*}
         */
        getPageFilePathInfo(aThemes = []) {
            const mResult = this.getMetaTagId();
            if (!mResult) {
                return {};
            }
            return aThemes.find((oFilePath) => {
                return oFilePath.recommend_redirect_to_app_path_seq_no === mResult;
            });
        }

        /**
         * Get MetaTagId
         * @return {boolean|number}
         */
        getMetaTagId() {
            const oMetaTagDom = this.getMetaTagDom();
            if (!oMetaTagDom) {
                return false;
            }
            return parseInt(oMetaTagDom.dataset.filepathid, 10);
        }

        /**
         * Get Meta Tag Dom
         * @return {Element}
         */
        getMetaTagDom() {
            return document.querySelector('#recommend-redirect-to-app-stats');
        }

        /**
         * Get Popup Dom
         * @return {{direct_to_app_dom: Element, main_dom: Element, close_dom: NodeListOf<Element>}}
         */
        getPopupDom() {
            return {
                /**
                 * Popup Main Parent Dom
                 */
                main_dom: document.querySelector('#mCafe24DirectToApp'),
                close_dom: document.querySelectorAll('.btnClose'),
                direct_to_app_dom: document.querySelector('.btnInstall'),
            };
        }

        /**
         * Check recommend install dom if existing
         * @return {boolean}
         */
        isInstallPopupPresent() {
            return document.body.contains(document.querySelector('#mCafe24AppInstall'));
        }

        /**
         * Pause for (n)th seconds
         * @param iSeconds
         * @return {Promise<any>}
         */
        pauseBySeconds(iSeconds = 0) {
            const MILLISECONDS = parseInt(`${iSeconds}000`, 10);
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve(true);
                }, MILLISECONDS);
            });
        }

        /**
         * Get popup settings on back-end
         * @return {Promise<boolean|*>}
         */
        async getSettings() {
            const oRequest = {
                method: 'GET',
                url: `${this.sAppUrl}/api/front/${this.sMallId}/${this.sModule}`,
                params: this.getRequestData(),
            };
            const oResponse = await this.helpers.apiRequest(oRequest);

            if (oResponse.status !== 200) {
                return false;
            }
            return oResponse.data.data;
        }

        getRequestData() {
            let sOsType = this.helpers.isIos() === true ? 'ios' : 'android';
            const mThemesPathId = this.getMetaTagId();
            return {
                data: {
                    shop_no: this.sShopNo,
                },
                nation: this.sNation,
                os_type: sOsType,
                shop_no: this.sShopNo,
                themes_path_id: mThemesPathId || null,
                display_url: decodeURIComponent(window.location.href),
            };
        }

        /**
         * Check if close or continue button has been clicked.
         * @return {boolean}
         */
        isPopupCloseOrContinueButtonClicked() {
            return this.helpers.getCookie(this.sCookieName) === 'true';
        }

        /**
         * Don't show popup for 1 day.
         */
        dontShowPopupForADay() {
            return this.helpers.setTodayCookie(this.sCookieName, true);
        }

        /**
         * Add CSS Script  at the end of <head> tag
         */
        addCssScript() {
            const CSS_SCRIPT = `<link rel="stylesheet" href="${this.sAppUrl}/css/front/${this.sModule}.css" type="text/css" />`;
            document.querySelector('head').insertAdjacentHTML('beforeend', CSS_SCRIPT);
        }

        /**
         * Get detected client (either android or iOS)
         * @return {null|*}
         */
        getDetectedOsClient() {
            const oOsFunctions = this.getOsFunctions();
            for (const [sOs, oFunctions] of Object.entries(oOsFunctions)) {
                if (oFunctions.check() === true) {
                    return oOsFunctions[sOs];
                }
            }
            return null;
        }

        /**
         * Open App Store
         * @param sUrlScheme
         * @param sLinkId
         * @param sUrl
         * @param bIsClickEvent
         * @return {*}
         */
        openIosApp(sUrlScheme, sLinkId, sUrl = null, bIsClickEvent = true) {
            let sAppLink = `${sUrlScheme}://`;
            let sEncodedLink = encodeURIComponent(sLinkId);
            if (sUrl !== null) {
                sAppLink = `${sAppLink}web?url=${encodeURIComponent(sUrl)}`;
            }

            let iClickedAt = +new Date();
            setTimeout(function () {
                if (+new Date() - iClickedAt < 2000) {
                    window.location.href = `itms-apps://itunes.apple.com/app/id${sEncodedLink}`;
                }
            }, 200);
            window.location.href = sAppLink;
        }

        /**
         * Get Helpers Os related methods
         * @return {{android: {checkServiceAvailability(): *, name: string, openAppStore(): Promise<void>, check(): *}, ios: {checkServiceOsAvailability(): *, name: string, openAppStore(): Promise<*>, check(): *}}|boolean|*}
         */
        getOsFunctions() {
            const oThis = this;
            return {
                ios: {
                    check() {
                        return oThis.helpers.isIos();
                    },
                    checkServiceOsAvailability() {
                        return oThis.helpers.checkOsAvailability('ios_production_information', oThis.aAddServiceInfo);
                    },
                    async openAppOnly(oFilePathInfo = {}) {
                        /**
                         * Not yet applicable as of now.
                         */
                        await oThis.helpers.logAppstoreRedirects('iOS', 'redirect_to_app', oThis.sShopNo, oThis.sMallId, oThis.sAppUrl, oThis.sModule, oFilePathInfo);
                        oThis.helpers.openAppOnly('redirect_to_app', oThis.sMallId, oThis.helpers.getUrlForDeepLink(), oThis.aAddServiceInfo);
                    },
                    async openAppStore(oFilePathInfo = {}) {
                        const aIosInformation = oThis.aAddServiceInfo.ios_production_information;
                        /**
                         * Not yet applicable as of now.
                         */
                        await oThis.helpers.logAppstoreRedirects('iOS', 'redirect_to_app', oThis.sShopNo, oThis.sMallId, oThis.sAppUrl, oThis.sModule, oFilePathInfo);
                        // We use own open IOS app method for recommend redirect to app specific behavior
                        return oThis.openIosApp(aIosInformation['app_id'], aIosInformation['apple_linkid'], oThis.helpers.getUrlForDeepLink());
                    },
                },
                android: {
                    check() {
                        return oThis.helpers.isAndroid();
                    },
                    checkServiceOsAvailability() {
                        return oThis.helpers.checkOsAvailability('android_production_information', oThis.aAddServiceInfo);
                    },
                    async openAppOnly(oFilePathInfo = {}) {
                        /**
                         * Not yet applicable as of now.
                         */
                        await oThis.helpers.logAppstoreRedirects('Android', 'redirect_to_app', oThis.sShopNo, oThis.sMallId, oThis.sAppUrl, oThis.sModule, oFilePathInfo);
                        oThis.helpers.openAppOnly('redirect_to_app', oThis.sMallId, oThis.helpers.getUrlForDeepLink(), oThis.aAddServiceInfo);
                    },
                    async openAppStore(oFilePathInfo = {}) {
                        const aAndroidInformation = oThis.aAddServiceInfo.android_production_information;
                        /**
                         * Not yet applicable as of now.
                         */
                        await oThis.helpers.logAppstoreRedirects('Android', 'redirect_to_app', oThis.sShopNo, oThis.sMallId, oThis.sAppUrl, oThis.sModule, oFilePathInfo);
                        oThis.helpers.openAndroidApp(aAndroidInformation['app_id'], aAndroidInformation['google_linkid'], oThis.helpers.getUrlForDeepLink());
                    },
                },
            };
        }
    }

    let oRecommendRedirectToApp = new RecommendRedirectToApp();

    /**
     * Initialize
     */
    if (document.querySelector('script[src*="/js/front/recommend-install.js"]') !== null) {
        window.oRecommendRedirectToApp = oRecommendRedirectToApp;
    } else {
        oRecommendRedirectToApp.init();
    }
})();
